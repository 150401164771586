import * as React from "react"

import {Link} from 'gatsby'
import Layout from '../../../components/layout/layout'

const pageTitle = "VR2WOA Blog"

const IndexPage = () => {
    return (
        <Layout pageTitle={pageTitle}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">

                            <h3 className="pt-4 pb-4">
                                <a href="">Conversation Starters 傾計貓紙</a>
                            </h3>
                            <hr />

                                <p className="text-muted">Dec 28, 2015</p>


                                <p>CQ CQ CQ this is ____ _____ ______. Calling CQ and standing by.</p>

                                <p>QSL, your signal is 59/58/55/44. Can you give me your signal report please?</p>

                                <p>Your signal is very strong. <i>(Contributed by VR2PK)</i>
                                </p>

                                <p>Looks like the propagation is very good.</p>

                                <p><b>Acknowledgement:</b><br />
                                    Roger / Copy / 100 percent</p>

                                <p>May I have your QTH?</p>

                                <p>May I have your name please?</p>

                                <p>How's the weather at your side?</p>

                                <p>Have a nice day and 73.</p>

                                <hr />

                                    <p>你的信號是 59/58/55/44。請問你可以給我信號報告嗎?<br />
                                        nǐ de xìn hào shì 59/58/55/44。qǐng wèn nǐ kě yǐ gěi wǒ xìn hào bào gào mǎ</p>

                                    <p>你的信號很強。<br />
                                        nǐ de xìn hào hěn qiáng</p>

                                    <p>電離層今天傳播不錯。<br />
                                        diàn lí céng jīn tiān chuán bō bú cuò</p>

                                    <p><b>Acknowledgement:</b><br />
                                        抄收 / Roger / 謝謝你 ／ 多謝你<br />
                                        chāo shōu / Roger / xiè xie nǐ duō xiè nǐ</p>

                                    <p>請問你現在在哪裡?<br />
                                        qǐng wèn nǐ xiàn zài zài nǎ lǐ</p>

                                    <p>請問你的名字是什麼?<br />
                                        qǐng wèn nǐ de míng zi shì shén me</p>

                                    <p>請問你哪邊天氣怎麼樣呢?<br />
                                        qǐng wèn nǐ nǎ biān tiān qì zěn me yàng ne</p>

                                    <p>希望你有一個幸福的一天。73<br />
                                        xī wàng nǐ yǒu yī gè xìng fú de yī tiān 73</p>


                        </div>
                </div>

            </div>

        </Layout>

    )
}

export default IndexPage
